import { Router } from '@inertiajs/core';

export const disableBackForwardCaching = (router: Router) => {
  // When navigating back using the browser's back button, refetch data.
  window.addEventListener('popstate', (event) => {
    event.stopImmediatePropagation();

    router.reload({
      replace: true,
    });
  });

  // When navigating back/forward without triggering 'popstate', Inertia can restore state from the history object.
  // In some cases, this can result in loading updated components with props of an older version.
  // By unsetting the state in the history object, we make sure Inertia always loads new props.
  window.history.replaceState(null, '');
};